import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import Heading from "../../components-v2/Base/HeadingBuilder";
import Image from "../ImageQuerys/LargeFeaturesImages";
import CtaSecondary from "../Buttons/secondary-cta";
import CtaPrimary from "../Buttons/primary-cta";
import check from "../../images/svg/checkmark.svg";
// import BusinessTypes from '../BusinessTypes/BusinessTypes';
import LargeFeaturesStyles from "./LargeFeaturesStyles";

const LargeFeatures = (props) => {
  const { className, sectionData, style, customItem, titleSymbol } = props;
  const { sectionTitle, sectionNavId, featureBlocks } = sectionData;
  return (
    <section
      className={`large-features ${className}`}
      id={sectionNavId}
      style={style}
    >
      {sectionTitle && (
        <Heading
          level={2}
          injectionType={2}
          className="text-center large-features__main-title text-h2"
        >
          {sectionTitle}
          <span className="blue_dot">.</span>{" "}
        </Heading>
      )}
      {featureBlocks.map((block, idx) => (
        <Row
          key={`${idx.toString()}__block`}
          align="middle"
          justify="center"
          gutter={[32, 32]}
          className="blocks"
          id={block.id || ""}
        >
          <Col
            lg={{
              span: 10,
              order: `${idx % 2 === 0 && !block.forceReverse ? 1 : 2}`,
            }}
            xs={24}
          >
            {block.blockImg ? (
              <Image
                imageName={block.blockImg}
                className="large-features__main-image"
                align={block.ImgAltTag}
              />
            ) : (
              customItem
            )}
          </Col>
          <Col
            lg={{
              span: 14,
              order: `${idx % 2 === 0 && !block.forceReverse ? 1 : 1}`,
            }}
            sm={24}
            xl={{
              span: 10,
              order: `${idx % 2 === 0 && !block.forceReverse ? 1 : 1}`,
            }}
          >
            <div className="large-features__info">
              <Heading level={3} injectionType={3} className="new-line">
                {block.blockTitle}
                <span className="blue_dot">{titleSymbol}</span>
              </Heading>
              {block.blockSubTitle && (
                <p className="large-features__subtitle">
                  {block.blockSubTitle}
                </p>
              )}
              {block.blockCustomSub && (
                <p
                  className="large-features__subtitle"
                  dangerouslySetInnerHTML={{ __html: block.blockCustomSub }}
                />
              )}
              {block.blockList && (
                <ul>
                  {block.blockList.map((item, i) => (
                    <li key={`${i.toString()}__list`}>
                      <img src={check} alt="" />
                      {typeof item !== "object" ? (
                        <p
                          style={{ fontSize: 18 }}
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      ) : (
                        <div>
                          <p>{item.title}</p>
                          <p className="large-features__listsub">
                            {item.subtext}
                          </p>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {!!block?.appendix && (
                <div
                  className="flex items-center -mt-4 text-black-200 font-[400] text-14 mb-6 ml-9 leading-[150%]"
                  dangerouslySetInnerHTML={{ __html: block.appendix }}
                />
              )}
              {block?.ctaInfo && (
                <CtaSecondary
                  ctaTitle={block.ctaInfo.ctaTitle}
                  target={block.ctaInfo.ctaTarget}
                />
              )}
              {block.ctaPrime && (
                <CtaPrimary
                  ctaTitle={block.ctaPrime.ctaTitle}
                  target={block.ctaPrime.ctaTarget}
                  openInNewTab={block.ctaPrime.openInNewTab}
                />
              )}

              {block.footer && (
                <div
                  className="text-p text-black"
                  dangerouslySetInnerHTML={{ __html: block.footer }}
                />
              )}
            </div>
          </Col>
        </Row>
      ))}
      <LargeFeaturesStyles />
    </section>
  );
};

LargeFeatures.propTypes = {
  className: PropTypes.string,
  sectionData: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  customItem: PropTypes.any,
  titleSymbol: PropTypes.string,
};
LargeFeatures.defaultProps = {
  className: "",
  sectionData: "",
  style: {},
  customItem: "",
  titleSymbol: ".",
};

export default LargeFeatures;
